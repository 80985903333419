import React, { useEffect, useRef, useState } from 'react';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { ReactComponent as CrossIcon } from 'images/cross-icon.svg';
import { ReactComponent as CopyIcon } from 'images/copy-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';

import { ReactComponent as FacebookIcon } from 'images/share-modal/facebook.svg';
import { ReactComponent as FacebookMessengerIcon } from 'images/share-modal/facebook-messenger.svg';
import { ReactComponent as TwitterIcon } from 'images/share-modal/twitter.svg';
import { ReactComponent as WhatsappIcon } from 'images/share-modal/whatsapp.svg';
import { ReactComponent as LinkedinIcon } from 'images/share-modal/linkedin.svg';
import { ReactComponent as EmailIcon } from 'images/share-modal/email.svg';
import { ReactComponent as MessagesIcon } from 'images/share-modal/messages.svg';

import Input from 'components/Input/Input';
import { ConversionType, trackConversion } from 'utils/analytics';
import { copyToClipboard } from 'utils/copyToClipboard';

import styles from './ShareModal.module.scss';

export const SHARE_MESSAGE =
  'I just signed up for Plannery, a new card just for healthcare professionals. Check it out!';

const socialPlatforms = [
  {
    name: 'Facebook',
    button: FacebookShareButton,
    icon: FacebookIcon,
  },
  {
    name: 'Messenger',
    button: FacebookMessengerShareButton,
    icon: FacebookMessengerIcon,
  },
  {
    name: 'Email',
    button: EmailShareButton,
    icon: EmailIcon,
  },
  {
    name: 'Twitter',
    button: TwitterShareButton,
    icon: TwitterIcon,
  },
  {
    name: 'LinkedIn',
    button: LinkedinShareButton,
    icon: LinkedinIcon,
  },
  {
    name: 'WhatsApp',
    button: WhatsappShareButton,
    icon: WhatsappIcon,
  },
];

interface SharedModalProps {
  referralLink: string;
  shareMessage: string;
  handleClose: () => void;
}

const ShareModal = ({ referralLink, handleClose, shareMessage }: SharedModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [copyClicked, setCopyClicked] = useState(false);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.display = 'block';
      modalRef.current.classList.add(styles.show);
    }
  }, [modalRef.current]);

  const onClose = () => {
    modalRef.current?.classList.remove(styles.show);
    modalRef.current?.classList.add(styles.hide);
    handleClose();
  };

  const handleClick = (platform: string) => {
    analytics.track('Card CTA Share Link Clicked', { platform });
    trackConversion(ConversionType.CardShareSocialClick);
  };

  const onCopy = () => {
    setCopyClicked(true);
    copyToClipboard(referralLink, '', false);
    analytics.track('Card CTA Copy Link Clicked');
  };

  return (
    <>
      <div ref={modalRef} className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>Share to...</div>
          <CrossIcon className={styles.headerIcon} onClick={onClose} />
        </div>

        <p className={styles.waitlistLabel}>Share the link to move up on the waitlist.</p>

        <div className={styles.content}>
          {socialPlatforms.map((platform) => (
            <div className={styles.socialButton} key={platform.name}>
              <platform.button
                title={shareMessage}
                url={referralLink}
                appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
                beforeOnClick={() => handleClick(platform.name)}
              >
                <platform.icon className={styles.socialIcon} />
                <p className={styles.iconLabel}>{platform.name}</p>
              </platform.button>
            </div>
          ))}
          <div className={styles.socialButton} onClick={() => handleClick('Messages')}>
            <a href={`sms:/&body=${shareMessage} ${referralLink}`}>
              <MessagesIcon className={styles.socialIcon} />
            </a>
            <p className={styles.iconLabel}>Messages</p>
          </div>
        </div>

        {copyClicked ? (
          <div className={styles.copiedWrapper}>
            <div className={styles.iconWrapper}>
              <CheckIcon className={styles.customCheckIcon} />
            </div>
            <p className={styles.copiedLabel}>Copied to clipboard.</p>
          </div>
        ) : (
          <div className={styles.inputWrapper}>
            <Input readOnly value={referralLink} />
            <CopyIcon onClick={onCopy} className={styles.copyIcon} />
          </div>
        )}
      </div>
      <div className={styles.backdrop} />
    </>
  );
};

export default ShareModal;
